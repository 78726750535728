import { ChevronLeft } from "src/assets/icons/system/system-icons"
import styled from "styled-components"
import { FlexContainer } from "../layout/elements"

interface BackProps {
  className?: string
  top: number
  left: number
  onBack: () => void
}

export const Back = (props: BackProps) => {
  const {
    className,
    top,
    left,
    onBack,
  } = props

  return (
    <Container
      className={className ?? ''}
      onClick={onBack}
      top={top}
      left={left}
    >
      <ChevronLeft/>
    </Container>
  )
}

const Container = styled(FlexContainer)<{top: number, left: number, circle?: boolean}>`
  align-items: center;
  justify-content: center;

  position: absolute;
  top: ${p => `${p.top}rem`};
	left: ${p => `${p.left}rem`};
  width: 1.875rem;
  height: 1.875rem;
  cursor: pointer;
  color: var(--text-secondary);

  > svg {
    width: 1rem;
    height: 1rem;
  }
`
