import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { PopupContainer } from '../../components/ui/Popup/Popup';
import { DESKTOP_GAP, DESKTOP_WIDTH } from '../../helpers/ui';
import { searchParams } from '../../helpers/url';
import { usePopupScroll } from '../../hooks/popup-hooks';
import { popupsStore, TPopup } from '../../store/Popups';
import store from '../../store/Store';
import Overlay from 'src/components/Overlay';

export const Popups = observer(() => {
  const popups = popupsStore.getPopups();
  const nodeRef = useRef(null);
  const Container = isMobile ? Mobile : Desktop;
	const userId = store.sessionData?.id
	const username = store.profile.firstName ?? ''

  const onClose = (id: string) => popupsStore.close(id)

  // useEffect(() => {
  //   if(popups.length > 0) disableBodyScroll(nodeRef.current!);
  //   else enableBodyScroll(nodeRef.current!);
  // }, [popups.length])

  return <Container id="popups" ref={nodeRef}>
    {
      popups.map(popup => (
        <React.Fragment key={`${popup.id}_${popup.depth}`}>
          <Popup
  					popup={popup}
  					id={popup.id}
  					userId={userId}
  					username={username}
          />

          {popup.hight && <StyledOverlay onClick={() => onClose(popup.id)}/>}
        </React.Fragment>
      ))
    }


  </Container>
})

const ANIMATE_DURATION = 800;

export const HeaderContext = React.createContext<{
  setOnClose: Dispatch<SetStateAction<() => void>>,
  setIsFirstScreen: Dispatch<SetStateAction<boolean>>,
  onDefaultClose: () => void,
}>({
  setOnClose: () => { },
  setIsFirstScreen: () => { },
  onDefaultClose: () => { }
});

const Popup = ({
  popup,
  id,
	userId,
	username,
}: {
  popup: TPopup,
  id: string
	userId?: number
	username?: string
}) => {
  const nodeRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);
  const sp = searchParams()

  const defaultOnClose = useCallback(() => {
    setVisible(false);
    setTimeout(() => popupsStore.close(id), ANIMATE_DURATION, ANIMATE_DURATION);
  }, []);

  const closeByLine = useCallback(() => {
    setTimeout(() => popupsStore.close(id), ANIMATE_DURATION);
  }, [])

  const [onClose, setOnClose] = useState(() => defaultOnClose)
  const [isFirstScreen, setIsFirstScreen] = useState(false)

  const contextValue = {
    setOnClose,
    onDefaultClose: defaultOnClose,
    setIsFirstScreen
  }

  usePopupScroll(nodeRef, closeByLine);

  useEffect(() => {
    setVisible(true);
  }, [])

  const styles = isMobile ? 'bottom-animate' : 'bottom-animate-desktop';
  const background = isMobile ? popup.background : 'rgba(248, 248, 248, 1)';

  return (
    <Container height={popup.hight}>
      <CSSTransition nodeRef={nodeRef} in={visible} timeout={ANIMATE_DURATION} classNames={styles} unmountOnExit>
        <HeaderContext.Provider value={contextValue}>
          <WindowContainer ref={nodeRef} depth={popup.depth}>
            <PopupBody>
              <PopupContainer
    						onClose={onClose}
    						title={popup.title}
    						background={background}
    						fixed={popup.fixedHeader}
    						firstScreen={isFirstScreen}
    						userId={userId}
    						username={username}
    					>
                {popup.content}
              </PopupContainer>
            </PopupBody>
          </WindowContainer>
        </HeaderContext.Provider>
      </CSSTransition>
    </Container>
  )
}

const Container =  styled.div<{height?: string}>`
  ${p => p.height && css`
    .bottom-animate-enter-active {
      transform: ${`translateY(${p.height})`};
      background-color: transparent;
      transition: transform 500ms, background-color 100ms linear 500ms;
    }

    .bottom-animate-enter-done {
      background-color: transparent;
      transform: ${`translateY(${p.height})`};
    }
  `}
`

const Mobile = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  bottom: -100%;
  z-index: 10;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
  overscroll-behavior-y: contain;
  -webkit-overscroll-behavior-y: contain;

  @media print {
    display: none;
  }
`
const Desktop = styled.div`
  width: ${DESKTOP_WIDTH}px;
  height: 100vh;
  /* overflow: auto; */
  position: absolute;
  left: 50%;
  transform: translate(${DESKTOP_GAP / 2}px, 100%);

  @media print {
    display: none;
  }
`

const WindowContainer = styled.div<{ depth: number }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${p => p.depth};
  will-change: transform;
`

const PopupBody = styled.div`
  will-change: transform;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const StyledOverlay = styled(Overlay)`
  display: block;
  opacity: 0.8;
  transition: transform 4s 1s;
  z-index: -1;
`
