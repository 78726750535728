import { useObserver } from "mobx-react-lite"
import store from "../../../store/Store"
import { ISelectOptions } from "../../../components/ui/Select"
import { CurrencyCode, Region } from "../../../libs"


export enum TariffPeriod {
	MONTH = 1,
	HALF_YEAR = 6,
	YEAR = 12,
}

export enum SubscribeModes {
	TRIAL = 'trial',
	TRIAL_FOURTEEN = 'trialFourteen',
	SUBSCRIBE = 'subscribe',
	RESUBSCRIBE = 'resubscribe',
}

export enum FreePeriod {
  TRIAL = 'trial',
  FOURTEEN_FREE_DAYS = 'fourteenFreeDays',
}

export interface ICard extends ISelectOptions {
	id: Region
  country: Region
  title: string
	code: CurrencyCode
	symbol: string
}

export const symbols = (currencyCode: CurrencyCode) => {
	switch(currencyCode) {
		case CurrencyCode.US:
			return '$'
		case CurrencyCode.KZT:
			return '₸'
		default:
			return '₽'
	}
}

// FIXME: duplicate?
export interface Card extends ISelectOptions {
	id: Region
  country: Region
  title: string
	code: CurrencyCode
	symbol: string
}

export const cards: Card[] = [
	{
	  id: Region.ru,
	  country: Region.ru,
	  title: 'Карта РФ',
		code: CurrencyCode.RUB,
		symbol: '₽',
	},
	{
		id: Region.kz,
		country: Region.kz,
	  title: 'Казахстанская карта',
		code: CurrencyCode.KZT,
		symbol: '₸',
	},
	{
	  id: Region.us,
	  country: Region.us,
	  title: 'Иностранная карта',
		code: CurrencyCode.USD,
		symbol: '$'
	},
]

export function useCard() {
  const { country } = useObserver(() => ({
    country: store.sessionData ? store.sessionData.region?.region : undefined
  }));

  if (country) {
		return cards.find(card => card.country === country) || cards[0]
	} else {
		return cards[0]
	}
}

export const getTitle = (tariffId: number) => {
  switch (tariffId) {
    case 1:
      return 'Ежемесячно'
    case 2:
      return 'Каждые 6 месяцев'
    case 3:
      return 'Каждые 12 месяцев'
  }
}
