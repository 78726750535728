import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { ReactNode, useEffect, useState } from "react";
import ym from "react-yandex-metrika";
import styled from "styled-components";
import { history } from "../../../MainRouter";
import { AlertCircleIcon, LogoBlackIcon } from "../../../assets/icons/system/system-icons";
import InfoBlock, { BlockStatus } from "../../../components/InfoBlock";
import Policy from "../../../components/Policy";
import { FlexContainer, MainPadding } from "../../../components/layout/elements";
import { Button } from "../../../components/ui/Button/Button";
import { Select } from "../../../components/ui/Select";
import api, { getHTTPMessage } from "../../../helpers/api";
import { numberWord } from "../../../helpers/strings";
import { gtmSend } from "../../../helpers/url";
import { Indi, PartnerLinkTargets, getUTMCommentForCP, show } from "../../../libs";
import { popupsStore } from "../../../store/Popups";
import store from "../../../store/Store";
import { windowsStore } from "../../../store/Windows";
import { getConsultationsLS, setConsultationsLS } from "../../../store/localStorageHelpers";
import { TrialClose } from "../../paywall/components/Close/TrialClose";
import { PromocodeButton } from "../../promocode/PromocodeButton";
import { FreePeriod, ICard, TariffPeriod, cards } from "../helpers/tariffs";
import { PaymentReminder } from "./payment-reminder";
import { PaymentSupport } from "./payment-support";


export const WINDOW_PAYMENT_PAYWALL = 'WINDOW_PAYMENT_PAYWALL';

export const AUTOTEST_EMAILS = process.env.REACT_APP_AUTOTEST_EMAILS?.replace(/\s/g, '').split(',');

export const openPaymentPaywallWindow = (tariffPeriod: TariffPeriod, onClose: () => void, freePeriod?: FreePeriod) => {
  windowsStore.open(
    WINDOW_PAYMENT_PAYWALL,
    <PaymentPaywall tariffPeriod={tariffPeriod}
      onClose={onClose}
      freePeriod={freePeriod} />,
    'fade'
  )
}

interface Title {
  title: string | ReactNode
  subtitle?: string | ReactNode
}
interface Titles {
  trial: Title
  month: Title
  year: Title
  fourteenFreeDays: Title
}

const SUBSCRIPTION_PRICE_MONTH = 499

interface PaymentPaywallProps {
  onClose: () => void,
  tariffPeriod: TariffPeriod,
  freePeriod?: FreePeriod,
  trialPeriod?: number
}

export const PaymentPaywall = observer((props: PaymentPaywallProps) => {
  const {
    onClose,
    tariffPeriod,
    freePeriod,
    trialPeriod
  } = props

  const [card, setCard] = useState(cards[0]);
  const [notify, setNotify] = useState(false);
  const [ isWaiting, setIsWaiting ] = useState(false);

  const promoData = store.promocode.promoData
  const userId = store.sessionData?.id || undefined;
  const email = store.profile.email || '';
  const userStatus = store.auth.userStatus;
  const expiryDate = store.sessionData?.indi?.expiryDate
  const tariffs = store.events.tariffs.map(item => ({ price: item.price, period: item.period }))
  const currentTariff = store.events.tariffs.find(item => item.period === tariffPeriod)
  const partner = store.partner.partner
  const finalPrice = Math.round(SUBSCRIPTION_PRICE_MONTH * (1 - store.partner.partnerDiscount) + Number.EPSILON)

  // const captchaRef = React.useRef<TReReCaptchaApi | null>(null);

  const titles: Titles = {
    trial: {
      title: <p>Попробуйте<br />{trialPeriod} {numberWord(trialPeriod!, ['день', 'дня', 'дней'])}<br /><span>бесплатно</span></p>,
      subtitle: 'Затем 499 ₽ в месяц',
    },
    month: {
      title: <p>Попробуйте<br />1 месяц<br /><span>бесплатно</span></p>
    },
    year: {
      title: <p>Продлите<br />подписку на год<br /><span>со скидкой</span></p>
    },
    fourteenFreeDays: {
      title: <p>Получите<br />14 дней<br /><span>в подарок</span></p>
    },
  }

  const buttonTitles = {
    trial: `Начать ${trialPeriod} ${numberWord(trialPeriod!, ['день', 'дня', 'дней'])} бесплатно`,
    month: 'Начать 1 месяц бесплатно',
    year: 'К оплате',
    fourteenFreeDays: 'Активировать 14 дней бесплатно'
  }

  useEffect(() => {
    store.partner.setPartnerDiscount(PartnerLinkTargets.ChronosPlus, partner)
  }, [])

  const isAutotest = AUTOTEST_EMAILS?.includes(email);

  useEffect(() => {
    store.events.loadTariffs(card.country)
  }, [card])

  if (!currentTariff) return null

  const type = () => {
    if (freePeriod && !promoData) return 'indiTrial'

    if (tariffPeriod === 12) return 'indiProlong'

    return 'indiSubscribe'
  }

  const title = (btn?: boolean) => {
    let key: keyof Titles = 'trial'

    if (freePeriod) {
      key = freePeriod
    } else {
      switch (tariffPeriod) {
        case 12:
          key = 'year'
          break
      }
    }

    if (promoData) {
      switch (promoData.actions.subscription) {
        case 1:
          key = 'month'
          break
      }
    }

    if (btn) {
      return buttonTitles[key]
    } else {
      return titles[key].title
    }

  }

  const prolong = () => {
    let data: { date: string, price?: string } | null = null

    if (freePeriod) {
      const period = trialPeriod ?? (freePeriod === FreePeriod.TRIAL ? 7 : 14)
      const date = dayjs().add(period, 'day').format('D MMMM')
      const monthPrice = finalPrice
      data = { date: `C ${date}`, price: `${monthPrice} ${card.symbol} в мес` }
    } else {
      switch (tariffPeriod) {
        case 12:
          const date = dayjs(expiryDate).add(360, 'day').format('D MMM YYYY')
          data = { date: 'Продляем до', price: date }
      }
    }

    if (promoData) {
      data = null
    }

    return data
  }

  const promocode = () => {
    if (!promoData) return

    let amount = 0

    switch (promoData.actions.subscription) {
      case 1:
        amount = finalPrice
        break
    }

    if (amount > 0) {
      return `- ${amount} ${card.symbol}`
    }
  }

  const payment = () => {
    let price = ''

    if (freePeriod) {
      const period = trialPeriod ?? (freePeriod === FreePeriod.TRIAL ? 7 : 14)
      price = `0 ${card.symbol} за ${period} ${numberWord(period, ['день', 'дня', 'дней'])}`
    } else {
      switch (tariffPeriod) {
        case 12:
          price = `${currentTariff.price} за 12 мес`
      }
    }

    if (promoData) {
      switch (promoData.actions.subscription) {
        case 1:
          price = '0 ₽ за 1 мес'
          break
      }
    }

    return price
  }

  const discount = () => {
    switch (tariffPeriod) {
      case 12:
        const monthTariff = tariffs.find(tariff => tariff.period === 1)?.price || finalPrice
        return monthTariff * 12 - currentTariff.price
      default:
        return null
    }
  }

  const hideOffer = () => {
    const consultationLS = getConsultationsLS()
    const updatedOffers = consultationLS?.offers?.map(offer => {
      if (offer.type === Indi.IndiUseOffer.Offers.FOURTEEN_FREE_DAYS) {
        offer.hide = true
      }

      return offer
    }) ?? []

    setConsultationsLS({
      ...consultationLS,
      offers: updatedOffers
    })

    api.indiUseOffer({
      offerName: Indi.IndiUseOffer.Offers.FOURTEEN_FREE_DAYS
    })
  }

  const closePaymentPaywall = () => {
    hideOffer()
    popupsStore.closeAll()
    store.promocode.resetPromocode()
    history.push({pathname: '/'})
    window.location.reload()
  }

  const getTrialPeriod = () => {
    return trialPeriod || (freePeriod === FreePeriod.TRIAL ? 7 : 14);
  }

  const onPayment = async () => {
    setIsWaiting(true)

    if (freePeriod === FreePeriod.TRIAL && !promoData) gtmSend({ 'event': 'ind_prognosis_trial_tariff_start', 'user_id': userId })
    if (tariffPeriod === 12) gtmSend({ 'event': 'ind_prognosis_check-out-bunner_click', 'user_id': userId })

    //@ts-ignore
    ym('reachGoal','indi_paywall_trial-start_click')

    if (promoData) {
      const result = await store.promocode.usePromocode(promoData.title, undefined, undefined, email)
      const isFreeCharge = result.subscription === 1 || result.subscription === 2
      if (isFreeCharge) {
        closePaymentPaywall()
        return
      }
    }

    if (isAutotest) {
      await api.indiActivateTrialForTest(userId, email);
      closePaymentPaywall();
      return;
    }

    try {

      // const captchaToken = await captchaRef.current?.executeAsync();

      const data = {
        // captchaToken,
        type: type(),
        trialPeriod: getTrialPeriod(),
        subscription: currentTariff.id,
        period: tariffPeriod,
        country: card.id,
        language: 'ru',
        userStatus,
        forUserEmail: email,
        comment: getUTMCommentForCP(),
        notify,
        promocodeTitle: promoData?.title
      }

      const result = await api.payment(
        data,
        freePeriod ? 'auth' : 'charge'
      );


      if (!result.failed) {
        if (freePeriod === FreePeriod.TRIAL && !promoData) {
          gtmSend({ 'event': 'ind_prognosis_trial_card_done', 'user_id': userId })
          ym('reachGoal', 'start-trial-chronos-plus')
        }

        setTimeout(() => {
          closePaymentPaywall()
        }, 1000)

        return
      }

      if (result.failed) {
        promoData && store.promocode.cancelPromocode(promoData.id, undefined)

        show({
          type: 'error',
          text: getHTTPMessage(result?.error?._errors),
        });
      }
    } catch (e) {
      promoData && store.promocode.cancelPromocode(promoData.id, undefined)
      console.error(`Payment trial error: ${e}`)
    }

    setIsWaiting(false)
  }

  const onCardSelectHandler = (card: ICard) => {
    store.events.loadTariffs(card.country)
    setCard(card)
  }

  return <Container>
    <Header>
      <TrialCloseIcon onClick={onClose} />
      <LogoBlackIcon />
      <Title>{title()}</Title>
      {freePeriod && !promocode() && <Subtitle>{titles.trial.subtitle}</Subtitle>}
    </Header>

    <Content>
      <div className='header'>
        <div className='title'>Итоги</div>
        {freePeriod && <PromocodeButton subscription applied={!!promoData} />}
      </div>
      {
        discount() && <Item>
          <p>Скидка</p>
          <span className='discount'>{`- ${discount()} ${card.symbol}`}</span>
        </Item>
      }

      {
        prolong() && <Item>
          <p>{prolong()!.date}</p>
          <span>{prolong()!.price}</span>
        </Item>
      }

      {
        promocode() && <Item>
          <p>Промокод</p>
          <span>{promocode()}</span>
        </Item>
      }

      {
        payment() && <Item>
          <p>К оплате сейчас</p>
          <span>{payment()}</span>
        </Item>
      }

      {
        freePeriod && !promoData && <StyledInfoBlock status={BlockStatus.ALERT}
          icon={<AlertCircleIcon />}>
          Для проверки карты мы спишем незначительную сумму и сразу её вернём
        </StyledInfoBlock>
      }
    </Content>

    <Content>
      <div className='title margin'>Оплата</div>
      <Select title="Карта" options={cards} value={card} onSelect={onCardSelectHandler} />
    </Content>

    <PaymentSupport />

    <Footer>
      {freePeriod && !promoData && <PaymentReminder notify={notify} setNotify={setNotify} />}

      <StyledButton
        onClick={onPayment}
        color="green"
        disabled={isWaiting}
      >{title(true)}</StyledButton>

      <Policy
        country={card.id}
        action={<span>Нажимая кнопку <b>«{title(true)}»</b></span>}
        fixedPosition={false}
        bottom={10} />
    </Footer>

    {/* <ReRecaptcha size="invisible" badge="bottomleft" ref={captchaRef}/> */}

  </Container>
})

const Container = styled.div`
	height: 100%;
	background: var(--popup-background);
	text-align: center;
	overflow-y: scroll;
	padding-bottom: 15rem;
`

const Header = styled(FlexContainer)`
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border-radius: 0px 0px 26px 26px;
	background: var(--color-white);
	padding-top: 3.5rem;
	padding-bottom: 3rem;
`

const Title = styled.div`
	font-size: 2rem;
	font-weight: 500;
	text-align: center;
	margin-top: 0.75rem;

	span {
		color: var(--color-green-dark);
	}
`

const Subtitle = styled.div`
	color: var(--text-secondary);
	text-align: center;
	margin-top: 0.75rem;
`

const Content = styled(MainPadding)`
  flex: 1;
	border-radius: 26px;
	background: var(--color-white);
	margin-top: 0.5rem;
	text-align: start;

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.title {
		font-size: 1.25rem;
		font-weight: 500;
	}

	.margin {
		margin-bottom: 1rem;
	}
`

const Item = styled.div`
	display: flex;
	justify-content: space-between;

	color: var(--text-secondary);
	font-size: 0.875rem;
	font-weight: 500;
	margin-top: 1rem;

	span {
		color: var(--text-primary);
		font-size: 1rem;
	}

	.discount {
		color: var(--color-green-dark);
	}
`

const Footer = styled(MainPadding)`
	bottom: -1rem;
	position: relative;
	width: 100%;
	border-radius: 26px;
	background: var(--color-white);
`

const StyledButton = styled(Button)`
  color: var(--text-primary);
`

export const PromocodeRow = styled(FlexContainer)`
  align-items: center;
  justify-content: space-between;
  color: var(--text-primary);
  padding: 0.875rem 0;
  border-bottom: 1px solid var(--element-border-10);
  margin-bottom: 0.875rem;
`

const StyledInfoBlock = styled(InfoBlock)`
	margin-top: 1rem;

	.left {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 3rem;

  	text-align: center;

		svg {
		width: 2.25rem;
		height: 2.25rem;
		}
	}
`

const TrialCloseIcon = styled(TrialClose)`
	left: 9rem;
	top: -2rem;
	position: relative;
`
