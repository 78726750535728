export function searchParams() {
  return new URLSearchParams(window.location.search);
}

export const gtmSend = (data: any) => {
  window.dataLayer.push(data)
}

export const mailTo = (email: string) => window.location.href = `mailto:${email}`;

export const getPathnames = () => window.location.pathname.split('/')

export function getUTM() {
  const sp = searchParams()
  const regex = /utm/
  const params: Record<string, string> = {}

  for (let key of sp.keys()) {
    if (key && regex.test(key)) {
      params[key] = sp.get(key) ?? ''
    }
  }

  return params
}
