import { makeAutoObservable } from "mobx";
import api from "../helpers/api";
import { getProfileLS, setProfileLS } from "./localStorageHelpers";

export class Rectification {
  rectificationDelay = ''
  rectificationSent = false

  constructor() {
    makeAutoObservable(this)
    this.loadRectificationDelay();
  }

  setRectificationOrdered() {
    const profileLS = getProfileLS()
    profileLS.rectificationDelay = new Date().toISOString()
    setProfileLS(profileLS)
  }

  loadRectificationDelay() {
    this.rectificationDelay = getProfileLS()?.rectificationDelay ?? ''
  }

  async orderRectification() {
    try {
      await api.orderRectification({ isDelayed: true })
      this.setRectificationOrdered()
      this.rectificationSent = true
    } catch(e) {
      console.error(`Error in ordering rectification: ${e}`)
    }
  }
}
