import styled from "styled-components"
import logo from "../assets/images/logo_chronos_plus.png"

export const LogoChronosPlus = () => {
  return (
    <Image src={logo}/>
  )
}

const Image = styled.img`
  height: 2.25rem;
  width: 13rem;
`
