export const AUTOTEST_EMAILS = process.env.REACT_APP_AUTOTEST_EMAILS?.replace(/\s/g, '').split(',') ?? [];

export const isTester = (email: string) => {
  return [
    'chron.autotest101@rambler.ru',
    'chron.autotest102@rambler.ru',
    'chron.autotest103@rambler.ru',
    'eugenekoiner@gmail.com',
    ...AUTOTEST_EMAILS,
  ].includes(email);
}
