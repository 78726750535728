import styled, { css } from "styled-components";
import { FlexContainer } from "../layout/elements";
import { Cross } from "../../assets/icons/system/system-icons";

interface CloseButtonProps {
  top: number
  right: number
  onClose: () => void
  circle?: boolean
}

export const CloseButton = (props: CloseButtonProps) => {
  const {
    top,
    right,
    onClose,
    circle,
  } = props

  return (
    <Container top={top} right={right} onClick={onClose} circle={circle}>
      <Cross />
    </Container>
  )
}

const Container = styled(FlexContainer)<{top: number, right: number, circle?: boolean}>`
	align-items: center;
  justify-content: center;

  position: absolute;
  top: ${p => `${p.top}rem`};
	right: ${p => `${p.right}rem`};
  width: 1.875rem;
  height: 1.875rem;
  cursor: pointer;

  > svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  ${p => p.circle && css`
    border-radius: 50%;
    color: var(--input-border);
    background: var(--input-background);
  `}
`
