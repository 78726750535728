import { observer } from "mobx-react-lite"
import { MainPadding, WindowContainer } from "../../components/layout/elements"
import { windowsStore } from "../../store/Windows"
import { WindowHeader } from "../windows/components/WindowHeader"
import styled from "styled-components"
import { SwitcherItem } from "../../components/SwitcherItem"
import { useCallback, useEffect, useState } from "react"
import store from "src/store/Store"
import { Indi } from "src/libs";
import { gtmSend } from "src/helpers/url"
import { InstallPWAPopup } from "src/modules/install-pwa/InstallPWAPopup"
import { Divider } from "src/components/ui/Divider"


const WINDOW_ID_NOTIFICATIONS = 'WINDOW_ID_NOTIFICATIONS'

export const openNotifications = () => windowsStore.open(
  WINDOW_ID_NOTIFICATIONS,
  <Notifications/>,
)

export const Notifications = observer(() => {
  const [everyDayPrognosis, setEveryDayPrognosis] = useState(false)
  const [importantEvents, setImportantEvents] = useState(false)
  const [showInstallPWA, setShowInstallPWA] = useState(false)

  const userId = store.sessionData?.id
  const isPWA = store.webPush.isPWA()

  const getSubscribe = useCallback(async () => {
    if (!userId) return

    const result = await store.webPush.getWebPushSubscribe(userId)
     if (result) {
      result.forEach((subscribe: Indi.WebPush.Subscribe.Subscription) => {
        if (subscribe.notificationId === Indi.WebPush.NotificationId.PROGNOSIS_REMINDER_NOTIFICATION_ID) {
          setEveryDayPrognosis(subscribe.status)
        }
        if (subscribe.notificationId === Indi.WebPush.NotificationId.IMPORTANT_EVENT_NOTIFICATION_ID) {
          setImportantEvents(subscribe.status)
        }
      })
     }
  }, [userId])

  const checkPWA = useCallback(() => {
    if (!isPWA) {
      setShowInstallPWA(true)
    }
  }, [isPWA])

  const closeInstallPWA = () => {
    setShowInstallPWA(false)
  }

  useEffect(() => {
    getSubscribe()
    checkPWA()
  }, [getSubscribe, checkPWA])

  const onClose = () => windowsStore.close(WINDOW_ID_NOTIFICATIONS)

  const onChangeEveryDayPrognosis = async () => {
    if (!isPWA) return

    if (Notification.permission !== "granted") {
      store.modal.showHandler.notificationsModal = true
      return
    }

    if (!userId) return

    const data = [
      {
        notificationId: Indi.WebPush.NotificationId.PROGNOSIS_REMINDER_NOTIFICATION_ID,
        status: !everyDayPrognosis
      }
    ]

    store.webPush.setWebPushSubscribe(userId, data)
    setEveryDayPrognosis(!everyDayPrognosis)

    gtmSend({ 'event': `indi_push_profile_prognosis_tumbler_${!everyDayPrognosis ? 'on' : 'off'}`, 'user_id': userId ? userId : '' })
  }

  const onChangeImportantEvents = async () => {
    if (!isPWA) return

    if (Notification.permission !== "granted") {
      store.modal.showHandler.notificationsModal = true
      return
    }

    if (!userId) return

    const data = [
      {
        notificationId: Indi.WebPush.NotificationId.IMPORTANT_EVENT_NOTIFICATION_ID,
        status: !importantEvents
      }
    ]

    store.webPush.setWebPushSubscribe(userId, data)
    setImportantEvents(!importantEvents)

    gtmSend({ 'event': `indi_push_profile_event_tumbler_${!importantEvents ? 'on' : 'off'}`, 'user_id': userId ? userId : '' })
  }

  return (
    <WindowContainer>
      <WindowHeader title={'Уведомления'} onBack={onClose}/>

      <Container>
        <SwitcherItem
          title={'Ежедневные прогнозы'}
          subtitle={'Напомним вам посмотреть прогноз на сегодня, если вы забудете зайти в приложение'}
          value={everyDayPrognosis}
          onChange={onChangeEveryDayPrognosis}
        />

        <Divider/>

        <SwitcherItem
          title={'Важные личные события'}
          subtitle={'Напомним вам о важных личных событиях, на которые стоит обратить внимание'}
          value={importantEvents}
          onChange={onChangeImportantEvents}
        />
      </Container>

      {showInstallPWA && <InstallPWAPopup webPush onClick={closeInstallPWA} />}
    </WindowContainer>
  )
})

const Container = styled(MainPadding)`

`
