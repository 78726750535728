import styled from "styled-components";
import { ArrowRight } from '../../../assets/icons/system/system-icons';
import ShareProduct from "./ShareProduct";
import { Button } from "../../../libs";

interface IFooterProps {
  toPayments(): void
  setContextMenuPosition(value: { top: number, left: number }): void
}

function Footer(props: IFooterProps) {
  return (
    <Container className={'no-print'}>
      <ShareProduct 
        onClickShareButton={(value: { top: number, left: number }) => props.setContextMenuPosition(value)} 
      />

      <CustomButton 
        color={'transparent'} 
        onClick={props.toPayments}
      >
        <ArrowRight />Вернуться к покупкам
      </CustomButton>
    </Container>
  );
}

export default Footer;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 2rem;
  padding: 1rem;

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 4rem;
    height: 4rem;
    color: var(--color-gradus-blue);
    margin-top: 0.5rem;

    svg {
      width: 60%;
      height: 60%;
    }
  }

  .text {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
  }
`

export const CustomButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 3rem;
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0.2px;

  & svg {
    margin: 0 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    transform: rotate(180deg);
  }
`
