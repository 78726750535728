import styled from "styled-components"
import { Checkbox } from '../../libs'
import { AlertTriangle } from "src/assets/icons/system/system-icons"

interface CheckboxProps {
  className?: string
  checked: boolean
  onChange(value: boolean): void
  text?: string | JSX.Element
  error?: string
  color?: string
  iconError?: boolean
}

export const CheckboxContainer = (props: CheckboxProps) => {
  const {
    className,
    checked,
    onChange,
    text,
    error,
    color = 'var(--color-green)',
    iconError = true,
  } = props

  return (
    <>
      <Container className={className ?? ''}>
        <StyledCheckbox
          color={color}
          checked={checked}
          onChange={onChange}
        />

        {
          text && <div>
            {text}
          </div>
        }
      </Container>

      {
        error && (
          <ErrorContainer>
            {iconError && <AlertTriangle />} {error}
          </ErrorContainer>
        )
      }
    </>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  font-size: 0.75rem;
`

const StyledCheckbox = styled(Checkbox)`
	color: white;
`

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  font-size: 0.75rem;
  color: var(--color-red);
  margin-top: 0.25rem;

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }
`
