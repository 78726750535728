import { observer } from 'mobx-react-lite';
import { GenderItem } from 'src/modules/edit/Edit';
import store from 'src/store/Store';
import styled from 'styled-components';
import { gtmSend, searchParams } from '../../helpers/url';
import { IPlace } from '../../libs';
import { Switcher } from '../ui/Switcher';
import { TextInput } from '../ui/TextInput';
import { OkSmall } from 'src/assets/icons/system/system-icons';

export const PrognosesForm = observer(({
  name,
  nameOnChange,
  nameError,
  namePlaceholder,
  date,
  dateOnChange,
  dateOnInvalid,
  dateError,
  time,
  timeOnChange,
  timeOnInvalid,
  timeError,
  timeNotion,
  place,
  placeOnChange,
  placeError,
  placePlaceholder,
  minDate,
  maxDate,
  originalDate,
  sendAnalitics = false,
  direct,
  email,
  emailOnChange,
  emailError,
  beforeAuth,
  gender,
  genderOnChange,
  genderItems,
}: {
  name: string,
  nameOnChange: (value: string) =>  void,
  nameError: string,
  namePlaceholder?: string,
  date: string,
  dateOnChange: (value: string) =>  void,
  dateOnInvalid: () => void,
  dateError: string,
  time: string,
  timeOnChange: (value: string) =>  void,
  timeOnInvalid: () => void,
  timeError: string,
  timeNotion?: string
  place: IPlace,
  placeOnChange: (value: IPlace) =>  void,
  placeError: string,
  placePlaceholder?:  string,
  minDate?: string,
  maxDate?: string,
  originalDate?: string,
  sendAnalitics?: boolean,
  direct?: boolean,
  email?: string,
  emailOnChange?: (value: string) =>  void,
  emailError?: string,
  beforeAuth?: boolean,
  gender?: GenderItem,
  genderOnChange?: (value: GenderItem) =>  void,
  genderItems?: GenderItem[]
}) => {
  const userId = store.sessionData?.id || 'unauthorized'
  const sp = searchParams()

  return <>
    <TextInput
      value={name}
      onChange={nameOnChange}
      title={'Имя'}
      error={nameError}
      onFocus={() => {
        if (sendAnalitics) {
          const event = !direct
            ? 'indi_prognosis_personal-info_name_click'
            : 'indi_prognos_pers-info_name_click-light'
          gtmSend({
            'event': event,
            'userId': userId,
            'utm_source': sp.get('utm_source'),
          })
        }
      }}
      placeholder={namePlaceholder}
    />

    <TextInput
      value={place.name}
      place={place}
      onChange={placeOnChange}
      title={'Место рождения'}
      type={'place'}
      error={placeError}
      onFocus={() => {
        if (sendAnalitics) {
          const event = !direct
            ? 'indi_prognosis_personal-info_place_click'
            : 'indi_prognos_pers-info_place_click-light'

          gtmSend({
            'event': event,
            'userId': userId,
            'utm_source': sp.get('utm_source'),
          })
        }
      }}
      placeholder={placePlaceholder}
    />

    {
      beforeAuth
        ? <DateTimeContainer error={!!(timeError || dateError)}>
            <DateTime>
              <TextInput
                value={time}
                onChange={timeOnChange}
                title={'Время рождения'}
                type={'time'}
                error={timeError}
                onInvalid={timeOnInvalid}
                onFocus={() => {
                  if (sendAnalitics) {
                    const event = !direct
                      ? 'indi_prognosis_personal-info_time_click'
                      : 'indi_prognos_pers-info_time_click-light'

                    gtmSend({
                      'event': event,
                      'userId': userId,
                      'utm_source': sp.get('utm_source'),
                    })
                  }
                }}
                placeholder='Как вас зовут?'
              />

              <TextInput
                value={date}
                onChange={dateOnChange}
                title={'Дата рождения'}
                type={'date'}
                error={dateError}
                minDate={minDate}
                maxDate={maxDate}
                onInvalid={dateOnInvalid}
                onFocus={() => {
                  if (sendAnalitics) {
                    const event = !direct
                      ? 'indi_prognosis_personal-info_date_click'
                      : 'indi_prognos_pers-info_date_click-light'

                    gtmSend({
                      'event': event,
                      'userId': userId,
                      'utm_source': sp.get('utm_source'),
                    })
                  }
                }}
              />
            </DateTime>

            <div className='info'>Если не знаете время рождения, потом можно изменить</div>
          </DateTimeContainer>
        : <>
            <TextInput
              value={date}
              onChange={dateOnChange}
              title={'Дата рождения'}
              type={'date'}
              error={dateError}
              minDate={minDate}
              maxDate={maxDate}
              notion={originalDate ? 'Дату можно изменить в пределах одного дня' : ''}
              onInvalid={dateOnInvalid}
              onFocus={() => {
                if (sendAnalitics) {
                  const event = !direct
                    ? 'indi_prognosis_personal-info_date_click'
                    : 'indi_prognos_pers-info_date_click-light'

                  gtmSend({
                    'event': event,
                    'userId': userId,
                    'utm_source': sp.get('utm_source'),
                  })
                }
              }}
            />

            <TextInput
              value={time}
              onChange={timeOnChange}
              title={'Время рождения'}
              notion={timeNotion}
              type={'time'}
              error={timeError}
              onInvalid={timeOnInvalid}
              onFocus={() => {
                if (sendAnalitics) {
                  const event = !direct
                    ? 'indi_prognosis_personal-info_time_click'
                    : 'indi_prognos_pers-info_time_click-light'

                  gtmSend({
                    'event': event,
                    'userId': userId,
                    'utm_source': sp.get('utm_source'),
                  })
                }
              }}
            />
          </>
    }

    {
      gender && genderOnChange && genderItems && (
        <SwitcherContainer errorDateTime={!!(timeError || dateError)}>
          <Label>Пол</Label>
          <Switcher
            items={genderItems}
            value={gender.value}
            onChange={genderOnChange}
            icon={<OkSmall/>}
          />
        </SwitcherContainer>
      )
    }

    {
      typeof email !== 'undefined' && emailOnChange && (
        <EmailTextInput
          title={'Ваша почта'}
          value={email}
          onChange={emailOnChange}
          placeholder={'example@domain.ru'}
          error={emailError}
          errorDateTime={!!(timeError || dateError)}
          onFocus={() => {
            gtmSend({
              'event': 'indi_prognos_pers-info_email_click',
              'userId': userId,
            })
          }}
        />
      )
    }
  </>
})

const DateTimeContainer = styled.div<{ error: boolean }>`
  position: relative;

  .info {
    position: absolute;
    bottom: ${p => p.error ? '-1.5rem' : '0.5rem'};
    font-size: 0.75rem;
    color: var('--text-secondary');
  }
`

const DateTime = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
`

const EmailTextInput = styled(TextInput)<{ errorDateTime: boolean }>`
  margin-top: ${p => p.errorDateTime ? '1.5rem' : '1rem'};
`

const Label = styled.div`
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  color: var(--text-secondary);
`

const SwitcherContainer = styled.div<{ errorDateTime: boolean }>`
  margin-top: ${p => p.errorDateTime ? '3rem' : '0.5rem'};
`
