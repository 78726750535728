import { observer } from "mobx-react-lite";
import beAware from 'src/assets/icons/payment/be_aware.png';
import personal from 'src/assets/icons/payment/personal.png';
import plan from 'src/assets/icons/payment/plan.png';
import quality from 'src/assets/icons/payment/quality.png';
import { Button } from "src/components/ui/Button/Button";
import { TrialClose } from "src/modules/paywall/components/Close/TrialClose";
import store from "src/store/Store";
import { windowsStore } from "src/store/Windows";
import styled from "styled-components";
import { openPaymentSubscription } from "./payment-subscription";
import { history } from "src/MainRouter";
import { popupsStore } from "src/store/Popups";
import { gtmSend, searchParams } from "src/helpers/url";
import { trialDurations } from "src/store/Trial";
import { openEditWindow } from "src/modules/edit/Edit";

const list = [
  {
    title: 'Планируйте свои дела',
    subtitle: 'по всем сферам жизни',
    icon: plan,
  },
  {
    title: 'Персональный прогноз',
    subtitle: 'на каждый день',
    icon: personal,
  },
  {
    title: 'Будьте в курсе будущих событий',
    subtitle: 'влияющих на вас',
    icon: beAware,
  },
  {
    title: 'Улучшайте качество жизни',
    subtitle: 'применяйте персональные рекомендации',
    icon: quality,
  },
];

export const WINDOW_PAYMENT_WITHOUT_CARD = 'WINDOW_PAYMENT_WITHOUT_CARD'

export const openPaymentWithoutCard = () => {
  windowsStore.open(
    WINDOW_PAYMENT_WITHOUT_CARD,
    <PaymentWithoutCard />,
    'fade'
  )
}

export const PaymentWithoutCard = observer(() => {
  const userId = store.sessionData?.id || ''
  const profile = store.profile

  const sp = searchParams()
  const period = Number(sp.get('d'))
  const trialDuration = trialDurations.includes(period) ? period : 7

  const onClose = () => {
    windowsStore.close(WINDOW_PAYMENT_WITHOUT_CARD)
  }

  const onTrial = async () => {
    gtmSend({ event: 'indi_prognos_trialpage_free7_click', user_id: userId })
    await store.trial.getTrialWithoutCard(trialDuration)
    await store.refresh()
		history.push('/')
		windowsStore.closeAll()
		popupsStore.closeAll()
  }

  const onPayment = () => {
    openPaymentSubscription()

    gtmSend({ event: 'indi_prognos_trialpage_pay_click', user_id: userId })
  }

  const onTrialHandler = () => {
    if (profile.email) {
      onTrial()
    } else {
      openEditWindow({beforeAuth: true, onProfileUpdate: onTrial})
    }
  }

  return (
    <Container>
      <div>
        <Header>
          <div className="controls"><TrialClose onClick={onClose} /></div>
          <div className="title">Попробуйте <br/> Астро-помощник <br/> <span>Chronos Plus</span></div>
          <div className="subtitle">Без привязки карты</div>
        </Header>

        <List>
          {
            list.map((item, index) => (
              <Item key={index}>
                <img src={item.icon} alt="icon"/>
                <div className="description">
                  <div className="title">{item.title}</div>
                  <div className="subtitle">{item.subtitle}</div>
                </div>
              </Item>
            ))
          }
        </List>
      </div>

      <Footer>
        <StyledButton onClick={onTrialHandler} color="gray">Попробовать {trialDuration} дней бесплатно</StyledButton>
        <StyledButton onClick={onPayment} color="green">Оформить подписку от 499 ₽/мес</StyledButton>
      </Footer>
    </Container>
  )
})


const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  padding: 1rem;
`

const Header = styled.header`
  text-align: center;

  .title {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.1;
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;

    & > span {
      background-image: linear-gradient(to right, #7D79EA, #D646D9, #E8AA63);
      color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
    }
  }

  .subtitle {
    font-size: 1.5rem;
    font-weight: 500;
  }
`

const List = styled.ul`
  margin-top: 2.5rem;
`

const Item = styled.li`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;

  img {
    width: 2.5rem;
    height: 2.5rem;
  }

  .description {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 1.25rem;
      font-weight: 500;
    }

    .subtitle {
      font-size: 0.75rem;
      color: var(--text-secondary);
    }
  }
`

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

`

const StyledButton = styled(Button)`
  color: var(--text-primary);
`
