import React from 'react';
import styled from 'styled-components';
import miscBannerImg from '../../../assets/images/black-friday.png';
// import { gtmSend } from '../../../helpers/url';
// import store from '../../../store/Store';

export const MainPageMiscBanner = () => {
    //const userId = store.sessionData?.id;

    const onClick = () => {
        //gtmSend({'event': `indi_banner_veb_osenravn`, 'user_id': userId ? userId : ''})
        setTimeout(() => {
            window.open('https://p.chronos.ru/bfcm24');
        }, 500)
        
    }
    return <Container onClick={onClick}>
        <img src={miscBannerImg} alt={"День осеннего равноденствия"} />
    </Container>
}

const Container = styled.div`
    position: relative;
    box-sizing: border-box;
    margin: 1rem 1rem 0;
    border-radius: 1rem;
    overflow: hidden;
    cursor: pointer;

    img {
        width: 100%;
        display: block;
    }
`
