import { makeObservable } from "mobx";

export class Pwa {
  constructor() {
    makeObservable(this)
  }

  installPWAPopupRef: null | HTMLDivElement = null

  setInstallPWAPopupRef(ref: HTMLDivElement | null) {
    this.installPWAPopupRef = ref
  }

  hideAppInstallPopup() {
    if (this.installPWAPopupRef) {
      this.installPWAPopupRef.style.display = 'none'
    }
  }
}
