import { IosShareIcon, MoreIcon, SamsungLoadIcon } from "src/assets/icons/system/system-icons"
import { windowsStore } from "src/store/Windows"
import styled from "styled-components"
import androidChrome from './assets/images/android/chrome.jpg'
import androidYandex from './assets/images/android/yandex.jpg'
import androidSamsung from './assets/images/android/samsung.jpg'
import iosChrome from './assets/images/ios/chrome.jpg'
import iosYandex from './assets/images/ios/yandex.jpg'
import iosSafari from './assets/images/ios/safari.jpg'
import { Button } from "src/components/ui/Button/Button"
import { WindowContainer } from "src/components/layout/elements"


export const WINDOW_ID_INSTALL_PWA  = 'WINDOW_ID_INSTALL_PWA'

export const openInstallPWA = () => {
  windowsStore.open(
    WINDOW_ID_INSTALL_PWA,
    <InstallPWA />,
    'fade'
  )
}

export const onClose = () => {
  windowsStore.close(WINDOW_ID_INSTALL_PWA)
}

export const InstallPWA = () => {
  const getImage = () => {
    const userAgent = navigator.userAgent.toLowerCase()

    if (userAgent.includes('android')) {
      if (userAgent.includes('samsungbrowser')) {
        return androidSamsung
      }

      if (userAgent.includes('yabrowser')) {
        return androidYandex
      }

      return androidChrome
    } else {
      if (userAgent.includes('chrome')) {
        return iosChrome
      }

      if (userAgent.includes('yabrowser')) {
        return iosYandex
      }

      return iosSafari
    }
  }

  const getList = () => {
    const userAgent = navigator.userAgent.toLowerCase()

    if (userAgent.includes('android')) {
      if (userAgent.includes('samsungbrowser')) {
        return (
          <List type="1">
            <li>Нажмите на <SamsungLoadIcon /> сверху справа</li>
            <li>Нажмите <span>Установить</span></li>
          </List>
        )
      }

      if (userAgent.includes('yabrowser')) {
        return (
          <List type="1">
            <li>Нажмите на <MoreIcon/> справа снизу</li>
            <li>Нажмите <span>Добавить ярлык на рабочий стол</span></li>
          </List>
        )
      }

      return (
        <List type="1">
          <li>Нажмите на <MoreIcon/> справа сверху</li>
          <li>Нажмите <span>Установить приложение</span> или <span>Добавить на главный экран</span></li>
        </List>
      )
    } else {
      if (userAgent.includes('yabrowser')) {
        return (
          <List type="1">
            <li>Нажмите на <MoreIcon/> снизу справа</li>
            <li>Нажмите <span>Добавить ярлык на телефон</span></li>
          </List>
        )
      }

      if (userAgent.includes('crios')) {
        return (
          <List type="1">
            <li>Нажмите на <IosShareIcon/> в нижней панели</li>
            <li>Нажмите <span>На экран «Домой»</span></li>
          </List>
        )
      }

      return (
        <List type="1">
          <li>Нажмите на <IosShareIcon/> справа сверху</li>
          <li>Нажмите <span>На экран «Домой»</span></li>
        </List>
      )
    }
  }

  return (
    <Container>
      <Content>
        <Title>
          Добавить <span>Chronos Plus</span> <br/>
          на главный экран
        </Title>

        <Image src={getImage()} alt='instruction'/>

        {getList()}
      </Content>

      <Button color={'gray'} onClick={onClose}>Закрыть</Button>
    </Container>
  )
}

const Container = styled(WindowContainer)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  height: 100%;
  padding: 3rem 1rem 2rem 1rem;
`

const Content = styled.div`
  width: 100%;
`

const Title = styled.div`
  display: table;

  color: var(--text-secondary);
  font-size: 1.5rem;
  font-weight: 500;
  width: 100%;
  text-align: center;

  span {
    background: linear-gradient(254.42deg, #3F72F5 13.07%, #2B14B8 93.32%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: var(--color-dodger-blue);
  }
`

const Image =  styled.img`
  width: 100%;
  margin-top: 2rem;
`

const List = styled.ol`
  color: rgba(0, 0, 0, 0.60);
  margin: 2rem 0;
  padding: 0;

  span {
    color: var(--color-dodger-blue);
  }

  li {
    counter-increment: list-counter;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  li::before {
    content: counter(list-counter) ". ";
    margin-right: 0.5em;
  }

  svg {
    display: inline;
    width: 1.5rem;
    height: 1.5rem;
    color: var(--color-dodger-blue);
    vertical-align: top;
  }
`
