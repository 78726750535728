import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import PwaLogoPath from 'src/assets/images/pwa-logo.png';
import store from 'src/store/Store';
import styled from 'styled-components';
import { Cross } from '../../assets/icons/system/system-icons';
import { FlexContainer } from '../../components/layout/elements';
import { Button } from 'src/components/ui/Button/Button';
import { openInstallPWA } from './InstallPWA';

export const InstallPWAPopup = observer(({
  onClick,
  webPush
}: {
  onClick: () => void;
  webPush?: boolean;
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (containerRef.current) {
      store.pwa.setInstallPWAPopupRef(containerRef.current)
    }
  }, [])

  const title = webPush ? 'Чтобы получать уведомления, добавьте на главный экран' : 'Добавьте на главный экран'

  const onClose =  (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
    onClick()
  }

  const onClickHandler = () => {
    onClick()
    openInstallPWA()
  }

  return <Container ref={containerRef} onClick={onClickHandler} className="ob-0">
    <CommonSection>
      <Logo><img src={PwaLogoPath} alt="PWA" /></Logo>
      <div onClick={onClick}>
        <Title>{title}</Title>
        <StyledButton color={'blue'} onClick={()=>{}}>Установить</StyledButton>
      </div>
      <CloseButton onClick={(event) => onClose(event)}><Cross /></CloseButton>
    </CommonSection>
  </Container>
})

const Container = styled.div`
  position: fixed;
  bottom: 0;
  z-index: var(--z-index-modal);
  width: 100%;
  background-color: #FFFFFF;
  font-size: 0.75rem;
  color: var(--text-secondary);
`

const CommonSection = styled(FlexContainer)`
  gap: 0.75rem;
  align-items: center;
  padding: 1rem;
`

const Logo = styled.div`
  > img {
    display: block;
    width: 3rem;
    height: 3rem;
  }
`

const CloseButton = styled(FlexContainer)`
  margin-left: auto;
  border-radius: 50%;
  background-color: rgba(246, 246, 246, 1);
  align-items: center;
  justify-content: center;
  min-width: 2rem;
  width: 2rem;
  height: 2rem;
  color: var(--text-secondary);
`

const Title = styled.div`
  font-size: 0.875rem;
  color: var(--text-primary);
  font-weight: 500;
  margin-bottom: 0.25rem;
`

const StyledButton = styled(Button)`
  padding: 0.25rem 0.75rem;
  width: fit-content;
`
