import { observer } from "mobx-react-lite"
import { useMemo } from "react"
import { Colors, Positions, Tip } from "src/components/Tip"
import { gtmSend } from "src/helpers/url"
import store from "src/store/Store"
import styled, { css } from "styled-components"
import { Indi } from 'src/libs'

const tipContent = {
  text : [
    'Обращайте внимание на напряженность и риски. Следуйте рекомендациям, чтобы минимизировать их влияние',
    'Период действия события указывает время, в течение которого событие активно и влияет на вас'
  ],
  buttonTitle : [
    'Далее',
    'Завершить'
  ]
}

interface  MyDayEventOnboardingProps {
  showTip: number
  setShowTip: (showTip: number) => void
  eventHeaderRef: React.RefObject<HTMLDivElement>
  eventRef: React.RefObject<HTMLDivElement>
  datesRef: React.RefObject<HTMLDivElement>
  skipTip: () => void
  onCloseTip: () => void
  event: Indi.Events.Event
}

export const MyDayEventOnboarding = observer((props: MyDayEventOnboardingProps) => {
  const {
    showTip,
    setShowTip,
    eventHeaderRef,
    eventRef,
    datesRef,
    skipTip,
    onCloseTip,
    event,
  } = props

  const userId = store.sessionData?.id

  const getTipTop = () => {
    const scrollTop = eventRef.current?.scrollTop ?? 0
    let bottom = 0

    if (showTip === 1) {
      bottom = eventHeaderRef.current?.getBoundingClientRect().bottom ?? 0
    } else {
      bottom = datesRef.current?.getBoundingClientRect().bottom ?? 0
    }

    return bottom + scrollTop
  }

  const tipButton = useMemo(() => {
    const onClickHandler = () => {
      if (!showTip) return

       if (showTip === 1) {
        setShowTip(2)
        gtmSend({'event': `indi_onboarding_events_description1`, 'user_id': userId ? userId : ''})
       } else {
         onCloseTip()
         gtmSend({'event': `indi_onboarding_events_description2`, 'user_id': userId ? userId : ''})
       }
    }

    const index = (showTip ?? 1) - 1

    return {
      title: tipContent.buttonTitle[index],
      onClick: onClickHandler,
      position: Positions.RIGHT
    }
  }, [showTip, onCloseTip, userId, setShowTip])

  const indicatorButton = useMemo(() => {
    return {
      length: 2,
      current: (showTip ?? 1),
    }
  }, [showTip])

  return (
    <TipWrapper showTip={showTip} height={getTipTop()}>
      <StyledTip
        color={Colors.DARK}
        text={tipContent.text[showTip - 1]}
        onClose={skipTip}
        trianglePosition={showTip === 1 ? Positions.TOP_RIGHT : Positions.TOP_LEFT}
        button={tipButton}
        indicator={indicatorButton}
        showTip={showTip}
        important={event.isImportant}
      />
    </TipWrapper>
  )
})

const TipWrapper = styled.div<{ showTip: number, height: number }>`
  position: absolute;
  top: ${p => `${p.height}px`};

  ${p => p.showTip === 1 && css`
    right: 10px;
  `}
`

const StyledTip = styled(Tip) <{ showTip: number, important: boolean }>`
  max-width: 320px;

  ${p => p.showTip === 1 && css`
    .triangle {
      right: ${`${p.important ? 4 : 5}rem`};
    }
  `}

  ${p => p.showTip === 2 && css`
    .triangle {
      left: 3rem;
    }
  `}
`
