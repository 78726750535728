import styled, { css } from 'styled-components';
import { Indi, IPromocode } from 'src/libs';
import { CheckedGreenIcon } from 'src/assets/icons/system/system-icons';
import { getTitle } from 'src/modules/payment/helpers/tariffs';

interface TariffSwitcherProps {
  tariffs: Indi.indiGetTariffs.Tariff[]
  current: Indi.indiGetTariffs.Tariff
  setTariff: (tariff: Indi.indiGetTariffs.Tariff) => void
  discount: number
  symbol: string
  className?: string
  row?: boolean
  useLocalTitle?: boolean
  promoData: IPromocode | null
}

export const TariffSwitcher = (props: TariffSwitcherProps) => {
  const {
    tariffs,
    current,
    setTariff,
    discount,
    symbol,
    className,
    useLocalTitle,
    promoData,
  } = props

  return (
    <Container className={className}>
      {
        tariffs.map(tariff => {
          const checked = tariff.id === current.id
          const promoDiscount = promoData?.actions.subscription === tariff.id ? 1 : null
          const finalDiscount = promoDiscount || discount
  			  const finalPrice = Math.round(tariff.price * (1 - finalDiscount))
          const periodText = tariff.title.replace(/^1/, '')

          const getDiscountPercentBadge = () => {
            let percent

            if (tariff.oldPrice) {
              percent = Math.ceil( (( tariff.oldPrice - tariff.price) / tariff.oldPrice) * 100 )
            }

            if (finalDiscount > 0) {
              percent = finalDiscount * 100
            }


            if (percent) {
              return <div className="item-badge">-{percent}%</div>
            }
          }

          return (
            <Item key={tariff.id} onClick={() => setTariff(tariff)} checked={checked} className='item'>

              {getDiscountPercentBadge()}

              <div>
                <div className='item-header'>
                  <span className='item-period'>{useLocalTitle ? getTitle(tariff.id) : periodText}</span>
                </div>

                <div className='item-content'>
                  <div className="item-price"> {finalPrice} {symbol}</div>
                  {
                    (tariff.oldPrice || finalDiscount > 0) &&
                    <div className='item-old-price'>
                        {finalDiscount > 0 ? tariff.price : tariff.oldPrice} {symbol}
                    </div>
                  }
                </div>
              </div>

              <CheckedIcon checked={checked}>{checked && <CheckedGreenIcon/>}</CheckedIcon>
            </Item>
          )
        })
      }
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  position: relative;
  padding: 0.125rem;
`

const Item = styled.div<{checked: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;

  position: relative;


  padding: 1rem;
  border-radius: 1rem;
  color: var(--text-primary);

  outline: 0.063rem solid var(--element-border-10);
  transition: outline-color .3s ease;

  ${p => p.checked && css`
    transition: outline-color .3s ease;
    outline: 0.125rem solid var(--color-green);
  `}

  & .item-badge {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(10%, -35%);

    padding: 0.219rem 0.5rem;
    font-size: 1.094rem;
    color: var(--text-primary);

    border-radius: 4.375rem;
    background-color: var(--color-ocher);
  }

  & .item-header {
    display: flex;
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--text-secondary);

    & .item-period::first-letter {
      text-transform: uppercase;
    }
  }

  & .item-content {
    display: flex;
    align-items: center;
    gap: 0.75rem;

    position: relative;
    margin-top: 0.5rem;

    & .item-price {
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.1;
      color: var(--text-primary)
    }

    & .item-old-price {
      margin-top: 0.125rem;

      font-size: 1.25rem;
      color: var(--text-third);
      text-decoration: line-through;
    }
  }

  & .item-footer {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: auto;

    & .tariff-title {
      font-size: 1rem;
      color: var(--text-secondary);
    }
  }
`

const CheckedIcon = styled.div<{checked: boolean}>`
  border-radius: 50%;
  width: 2rem;
  height: 2rem;

  ${p => !p.checked && css`
    border: 0.125rem solid var(--text-third);
  `}

  svg {
    width: 1.875rem;
    height: 1.875rem;
  }
`
