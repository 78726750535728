import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect } from "react";
import { isMobile } from "react-device-detect";
import ym from "react-yandex-metrika";
import { LogoChronosPlus } from "src/components/LogoChronosPlus";
import { CheckboxContainer } from "src/components/ui/CheckboxContainer";
import { IAuthIndi } from "src/store/Auth";
import styled, { css } from "styled-components";
import { Warning } from "../../../assets/icons/system/system-icons";
import background from '../../../assets/images/backgrounds/bg_component.jpg';
import ReRecaptcha, { TReReCaptchaApi } from "../../../components/ReRecaptcha";
import { WindowFormContainer } from "../../../components/layout/elements";
import { Button } from "../../../components/ui/Button/Button";
import ConfirmCodeInput, { IRefConfirmCodeInput } from "../../../components/ui/ConfirmCodeInput";
import CountDownTimer, { IRefCountDownTimer } from "../../../components/ui/CountDownTimer";
import api, { HTTPMessages, getHTTPMessage, s3urls } from "../../../helpers/api";
import { gtmSend, searchParams } from "../../../helpers/url";
import { Device, Indi, InputContainer, PhoneInput } from "../../../libs";
import store from "../../../store/Store";
import { windowsStore } from "../../../store/Windows";
import { FreePeriod } from "../../payment/helpers/tariffs";
import { openPaymentWindow } from "../../paywall/Paywall";
import { IconStyles, WindowHeader } from "../../windows/components/WindowHeader";
import CountryList from "../CountryList";
import { CopyRight, Footer, MiniTitle } from "../Registration";
import EmailAuth, { WINDOW_ID_EMAIL_AUTH } from '../email/index';
import { DealAcceptance } from "src/components/DealAcceptance";


export const WINDOW_ID_SMS_AUTH = 'WINDOW_ID_SMS_AUTH';
export enum AUTH_STEP {
  PHONE = 1,
  CODE = 2
}

export interface IAuthSmsProps {
  title?: string;
  subtitle?: string;
  codeConfirmSubtitle?: string;
  onlyPhoneNumberConfirm?: boolean;
  onSuccess?: (channel: string, sessionData?: IAuthIndi) => void;
  direct?: boolean;
}

export const openSmsAuthWindow = (
  onSuccess?: (channel: string, sessionData?: IAuthIndi) => void,
  title?: string,
  subtitle?: string,
  codeConfirmSubtitle?: string,
  onlyPhoneNumberConfirm?: boolean,
  direct?: boolean,
) => {
  windowsStore.open(
    WINDOW_ID_SMS_AUTH,
    <SmsAuth
      title={title}
      subtitle={subtitle}
      codeConfirmSubtitle={codeConfirmSubtitle}
      onlyPhoneNumberConfirm={onlyPhoneNumberConfirm}
      onSuccess={onSuccess}
      direct={direct}
    />
  )
}

export const SmsAuth = observer(function (props: IAuthSmsProps) {
  const [step, setStep] = React.useState<AUTH_STEP>(AUTH_STEP.PHONE);
  const [phoneNumber, setPhoneNumber] = React.useState<string>('');
  const [code, setCode] = React.useState<string>('');
  const [countryCode, setCountryCode] = React.useState<string>('ru');
  const [showCountryList, setShowCountryList] = React.useState<boolean>(false);
  const [isRegistration, setIsRegistration] = React.useState<boolean>(false);

  const [phoneMessage, setPhoneMessage] = React.useState<string>('');
  const [codeMessage, setCodeMessage] = React.useState<string>('');
  const [isCorrectNumber, setIsCorrectNumber] = React.useState(false);
  const [phoneCountry, setPhoneCountry] = React.useState('');

  const [disabledUi, setDisabledUi] = React.useState<boolean>(true);
  const [codeRequestNumber, setCodeRequestNumber] = React.useState<number>(0);
  const [consent, setConsent] = React.useState(true);

  const captchaRef = React.useRef<TReReCaptchaApi | null>(null)

  const CodeInputRef = React.useRef<IRefConfirmCodeInput>(null);
  const CountDownRef = React.useRef<IRefCountDownTimer>(null);

  const userId = store.sessionData?.id || 'unauthorized';

	const sp = searchParams();
	const partner = sp.get('partner') ?? undefined

  const windows = windowsStore.getWindows();

  useEffect(() => {
    const sp = searchParams()
    const event = !props.direct
      ? 'chronos_mobile_number'
      : 'chronos_mobile_number-light'

    gtmSend({
      'event': event,
      'user_id': userId,
      'utm_source': sp.get('utm_source'),
    });

    store.pwa.hideAppInstallPopup()
  }, []);

  useEffect(() => {
		if (store.isAuth && !props.onlyPhoneNumberConfirm) {
			windowsStore.close(WINDOW_ID_SMS_AUTH);
		}
  }, [store.isAuth]);

  useEffect(() => {
    setDisabledUi(!Boolean(captchaRef.current));
  }, [captchaRef.current]);

  const stateStepErrorHandler = (err: any) => {
    const messages = getHTTPMessage(err._errors)
    step === AUTH_STEP.PHONE
      ? (setPhoneMessage(`${messages}`))
      : (setCodeMessage(`${messages}`))
    throw err
  }

  const successAuthHandler = (sessionData?: any) => {
    if (props.onSuccess) {
      props.onSuccess(phoneNumber, sessionData);
    } else if (isRegistration) {
      const tariff = store.events?.tariffs?.find(item => item.period === 1)!
      openPaymentWindow(tariff, { freePeriod: FreePeriod.TRIAL });
      gtmSend({ 'event': 'chronos_mobile_done', 'user_id': userId });
    }

    windowsStore.close(WINDOW_ID_SMS_AUTH);
    gtmSend({ 'event': 'indi_registration_successful' });
  }

  const sendPhone = useCallback(async (phoneNumber: string) => {
    try {
      captchaRef.current?.reset();
      const token = await captchaRef.current?.executeAsync();

      if (token) {
        let result: any;

        if (props.onlyPhoneNumberConfirm) {
          result = await api.verifyPhone(`+${phoneNumber}`, token);
        } else {
					const addition: Indi.AuthenticateWithPhone.Addition = {
						partner
					}

          result = await api.authenticateWithPhone(`+${phoneNumber}`, token, addition);

					if (!result.isExists) {
						await store.logger.createLog({
							date: dayjs().toString(),
							device: isMobile ? Device.MOBILE : Device.DESKTOP,
							phoneNumber,
							country: phoneCountry,
						})
					}
        }

        setCodeRequestNumber(codeRequestNumber + 1);
        setIsRegistration(!result?.isExists)
        return result;
      } else {
        console.error('Captcha error')
        throw Error('Captcha error')
      }
    } catch (err) {
      console.error('sendPhone err -', err)
      throw err
    }
  }, [partner, codeRequestNumber, props.onlyPhoneNumberConfirm, phoneCountry])

  const onSendPhone = (evt?: any) => {
		if (checkNumber()) return

    setPhoneMessage('');
    setCodeMessage('');
    sendPhone(phoneNumber)
      .then(res => {
        setStep(AUTH_STEP.CODE);

				store.logger.updateLog({
					phoneNumber,
					inputWindowOpened: true
				})

        gtmSend({ 'event': 'chronos_mobile_code', 'user_id': userId });
        res?.isExists ? ym('reachGoal','auth-chronos-plus') : ym('reachGoal','reg-chronos-plus')
      })
      .catch((err) => {
        stateStepErrorHandler(err);
      })
  }

  const sendCode = async (phoneNumber: string, code: string) => {
    try {
      let result: any = null;
      let sessionData: any = null;
      if (props.onlyPhoneNumberConfirm) {
        result = await api.verifyPhoneCode(`+${phoneNumber}`, code)
      } else {

        captchaRef.current?.reset();
        const captchaToken = await captchaRef.current?.executeAsync();
        result = await api.signUpConfirm(`+${phoneNumber}`, code, captchaToken);

        if (result.code) {
          sessionData = await store.initLogin(result.code)
        };
      }

      return sessionData;
    } catch (err: any) {
      stateStepErrorHandler(err);
      return null
    }
  }

  const onCodeFilling = (code: string) => {
    setPhoneMessage('');
    setCodeMessage('');

    if (code) {
      setCode(code);
			store.logger.updateLog({
				phoneNumber,
				confirmationCodeWritten: true
			})

      setTimeout(() => {
        sendCode(phoneNumber, `${code}`)
          .then((sessionData: any) => {
            const event = !props.direct
              ? 'indi_auth_confirmation-code_done'
              : 'indi_auth_conf-code_done-light'

            ym('reachGoal','indi_auth_confirmation-code_done');
            gtmSend({'event': event, 'user_id': userId});

            successAuthHandler(sessionData);
          })
      }, 16);
    }
  }

  const onBack = () => {
    if (step === AUTH_STEP.PHONE && !showCountryList) {
      windowsStore.close(WINDOW_ID_SMS_AUTH);
    } else if (step === AUTH_STEP.CODE) {
      setStep(AUTH_STEP.PHONE);
    } else if (showCountryList) {
      setShowCountryList(false);
    }
  }

  const onFlagClick = () => {
    setShowCountryList(true);
  }

  const onCountrySelect = (countryCode: string) => {
    setPhoneNumber('');
    setCountryCode(countryCode);
    setPhoneMessage('');
    setShowCountryList(false);
  }

  const requestCode = () => {
    setPhoneMessage('');
    setCodeMessage('');

    if (codeRequestNumber >= 5) {
      setCodeMessage(HTTPMessages.default);
      return;
    }

    sendPhone(phoneNumber)
      .then((result) => {
        console.log('again requestCode ok - ', result);
      })
      .catch((err) => {
        stateStepErrorHandler(err);
      })
      .finally(() => {
        CodeInputRef.current?.clear();
        CountDownRef.current?.restart();
      })
  }

	const checkNumber = () => {
		if (!isCorrectNumber) {
			setPhoneMessage(getHTTPMessage(['wrong channel']))
			return true
		}
	}

  const onSuccessMailAuth = (channel: string, sessionData?: any) => {
    props.onSuccess?.(channel, sessionData);
    windowsStore.close(WINDOW_ID_EMAIL_AUTH);
    windowsStore.close(WINDOW_ID_SMS_AUTH);
  }

  const openMailAuth = () => {
    windowsStore.open(
      WINDOW_ID_EMAIL_AUTH,
      <EmailAuth onSuccess={onSuccessMailAuth}/>,
      'fade'
    )
  }

	const onChangeHandler = (value: string) => {
		setPhoneNumber(value)
		setPhoneMessage('')
	}

  const onClose = () => {
    windowsStore.closeAll()
  }

  return (
    <StyledWindowFormContainer background={background}>
      <StyledWindowHeader
        title={!showCountryList ? (step === AUTH_STEP.PHONE ? (props.title || '') : 'Введите код из смс') : 'Выбор страны'}
        onBack={onBack}
        iconStyle={IconStyles.WITH_BG}
        onClose={windows.length > 1 ? onClose : undefined}
      />

      <SmsRegistrationContent paddingBlock={!showCountryList} morePadding={step === AUTH_STEP.CODE}>
        <LogoChronosPlus/>

        {
          !showCountryList
            ? <>
                <MiniTitle>
                  { step === AUTH_STEP.PHONE
                    ? <Message>
                        {props.title || 'Отправим на телефон код для входа в аккаунт'}
                      </Message>
                    : <Message>{`${props.codeConfirmSubtitle || 'Мы отправили код для входа'} на номер +${phoneNumber}`}</Message>
                  }
                </MiniTitle>

                {
                  step === AUTH_STEP.PHONE
                    ? <InputContainer
                        label=""
                        message={phoneMessage && <><Warning/>{phoneMessage}</>}
                      >
                        <StyledPhoneInput
                          size="big"
                          country={countryCode}
                          lang="ru"
                          value={phoneNumber}
                          onCountryFlagClick={onFlagClick}
                          onChange={onChangeHandler}
                          mode={phoneMessage ? 'error' : 'normal'}
    											isCorrectNumber={isCorrectNumber}
    											setIsCorrectNumber={setIsCorrectNumber}
    											setPhoneCountry={setPhoneCountry}
                        />
                      </InputContainer>
                    : <>
                        <ConfirmCodeInput ref={CodeInputRef} length={4} onFilling={onCodeFilling} errorMessage={codeMessage} />
                        <CountDownTimer ref={CountDownRef} minutes={1} onClick={requestCode} />
                      </>
                }
              </>
            : <StyledCountryList onSelect={onCountrySelect} />
        }

        {
          !showCountryList &&
            <FooterStyled>
              {
                step === AUTH_STEP.PHONE &&
                  <Button
                    onClick={onSendPhone}
                    disabled={disabledUi || !consent}
                    color='gradient_purpure'
                  >
                      {'Получить код'}
                  </Button>
              }

              {
                step === AUTH_STEP.PHONE &&
                  <>
                    {
                      !props.onlyPhoneNumberConfirm &&
                        <SignInByEmail  onClick={openMailAuth}>Уже есть аккаунт с почтой?</SignInByEmail>
                    }

                    <StyledCheckboxContainer
                      checked={consent}
                      onChange={setConsent}
                      text={<DealAcceptance/>}
                      color={'var(--color-cornflower-blue)'}
                    />
                  </>
              }
            </FooterStyled>
        }

        <ReRecaptcha
          size="invisible"
          badge="bottomleft"
          ref={captchaRef}
        />
      </SmsRegistrationContent>
    </StyledWindowFormContainer>
  );
});

const StyledWindowFormContainer = styled(WindowFormContainer)<{background: string}>`
  background: ${p => `url(${p.background}) 0 0 / cover no-repeat`};
`

let SmsRegistrationContent = styled.div <{ paddingBlock: boolean; morePadding: boolean; }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  box-sizing: border-box;
  padding: 0 1rem 1rem 1rem;

  ${p => !p.paddingBlock && css`
    padding-left: 0;
    padding-right: 0;
  `}

  ${p => p.morePadding && css`
    padding-top: 0;
    padding-left: 2rem;
    padding-right: 2rem;
  `}
`;

const StyledWindowHeader = styled(WindowHeader)`
  justify-content: space-between;
`;

const FooterStyled = styled(Footer)`
  padding: 0;
  margin-top: 1rem;
`;

const Message = styled.div`
  position: relative;
  margin: 0 auto;
  text-align: center;
  font-weight: 500;
  color: var(--text-secondary);
  width: 70%;
  font-size: 1rem;
  margin-top: 1.5rem;
`;

const StyledPhoneInput = styled(PhoneInput)`
  input {
    font-size: 1.625rem !important;
    border-radius: 1rem;
  }
`

const StyledCountryList = styled(CountryList)`
  margin-top: 1.5rem;
`

const SignInByEmail = styled.div`
  text-decoration: underline;
  color: var(--color-cornflower-blue);
  font-size: 0.875rem;
  width: 100%;
  text-align: center;
  margin-top: 1.5rem;
`

const StyledCopyRight = styled(CopyRight)`
  padding: 0;
  text-align: left;
`

const StyledCheckboxContainer = styled(CheckboxContainer)`
  margin-top: 2rem;
`
