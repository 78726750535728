import styled, { css } from "styled-components"

interface Item {
  value: any
  title: string
}

interface SwitcherProps {
  className?: string
  items: Item[];
  value: any;
  onChange(value: any): void;
  icon?: React.ReactNode
}

export const Switcher = (props: SwitcherProps) => {
  const {
    className = '',
    items,
    value,
    onChange,
    icon,
  } = props

  return (
    <Container className={className}>
      {
        items.map((item: any, index: number) => {
          return (
            <Button
              key={`${index}_switcher_btn`}
              onClick={() => onChange(item)}
              active={item.value === value}
            >
              {item.value === value && icon} {item.title}
            </Button>
          )
        })
      }
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  background-color: var(--switcher-background);
  border-radius: 0.75rem;
  padding: 0.25rem;
`

const Button = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  cursor: pointer;
  padding: 0.5rem 0;
  font-size: 0.875rem;
  font-weight: 500;

  ${props => props.active && css`
    background-color: var(--switcher-button-background);
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    border-radius: 0.5rem;
  `}

  svg {
    color: var(--text-secondary);
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.25rem;
  }
`
