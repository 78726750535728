import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import Sphere from "./Sphere";
import { IData } from "./types";
import dayjs from 'dayjs';
import EditForm from "./EditForm";
import store from '../../../../store/Store';
import ButtonSwitcher, { IOption } from '../../../../components/ui/ButtonSwitcher';
import { contextButtonPositionType } from '../../../../components/ui/ContextMenu/types';
import { copyToClipboard } from '../../../../helpers/strings';
import DropDown from '../../../../components/ui/ContextMenu/DropDown';
import Footer from '../../components/Footer';
import ContextMenu from '../../../../components/ui/ContextMenu';
import ProductConfirmation from '../../components/Confirmation';
import { SOLAR, WINDOW_ID_CONFIRMATION, WINDOW_ID_SOLAR, PRODUCTS_SECTION } from '../../helpers/constants';
import { windowsStore } from '../../../../store/Windows';
import { MainPadding, WindowContainer } from '../../../../components/layout/elements';
import { WindowHeader } from '../../../windows/components/WindowHeader';
import { isMobile } from 'react-device-detect';
import { searchParams } from '../../../../helpers/url';
import { history } from '../../../../MainRouter';
import { useBackWindow } from '../../../../hooks/router-hooks';
import { ROUTES } from '../../../../helpers/routes';
import { s3urls } from '../../../../helpers/api';
import { show } from '../../../../libs';
import { observer } from 'mobx-react-lite';
import { FreeFourteenBanner } from '../components/FreeFourteenBanner';

export type FormatsType = 'days' | 'hours'
export const FORMAT_DAYS = 'days'
export const FORMAT_HOURS = 'hours'

const solarFormatsOptions: IOption[] = [
  {
    value: FORMAT_DAYS,
    label: '12 дней'
  },
  {
    value: FORMAT_HOURS,
    label: '24 часа'
  }
];

interface ISolarProps {
  token?: number
  sharedToken?: string
}

export default observer(function Solar(props: ISolarProps) {
  const pathname = `${ROUTES.PRODUCTS_SOLAR}/${props.token}`
  useBackWindow(pathname, WINDOW_ID_SOLAR);

  document.title = `Продукты • Солярный день рождения`;

  const [data, setData] = useState<IData | null>(null)
  const [format, setFormat] = useState(store.solar.activeFormat)
  const [edit, setEdit] = useState(false)
  const [contextMenuPosition, setContextMenuPosition] = useState<contextButtonPositionType>(null)
  const [showBanner, setShowBanner] = useState(false)

  // const id = document.location.pathname.split('/')[2]
  const sp = searchParams()
  const subscriptionInfo = store.sessionData?.indi

  const ref = React.createRef<HTMLDivElement>();

  useEffect(() => {
    if (props.sharedToken) {
      store.solar.getSharedSolar(props.sharedToken)
        // @ts-ignore
        .then(setData)

      store.products.setIsSharedProduct('emotions')
    } else {
      props.token && store.solar.getSolar(props.token)
        //@ts-ignore
        .then(setData)
    }

    store.products.getProducts()
    store.products.getPurchasedProducts()
    windowsStore.setContextMenu(solarContextMenu)

    return () => {
      windowsStore.setContextMenu(null)
      props.sharedToken && store.products.setIsSharedProduct(null)
    }
  }, [])

  // прокручиваем до активной сферы
  useEffect(() => {
    const anchor = document.getElementById('#takePlace');
    if (anchor && ref.current) {
      ref.current.scrollTo({ top: anchor.offsetTop - 70, behavior: "smooth" });
    }
  }, [data, ref.current])

  useEffect(() => {
    if (!subscriptionInfo?.trialExpiryDate) {
      setShowBanner(true)
    }
  }, [subscriptionInfo])

  const switchFormat = (format: FormatsType) => {
    store.solar.setActiveFormat(format)
    setFormat(format)
  }

  const saveLink = async () => {
    try {
      const token = store.solar.solarId && await store.solar.getShareToken(store.solar.solarId.toString())
      const link = `${window.location.host}/solar/${token}/shared`

      copyToClipboard(link)

      if (isMobile) {
        window.navigator.share({
          title: 'Хронос',
          text: 'Солярный день',
          url: link
        })
      } else {
        copyToClipboard(link);

        show({
          text: 'Ссылка скопирована',
          type: 'success'
        })
      }
    } catch {
      show({
        text: 'Не удалось скопировать ссылку',
        type: 'error'
      })
    }
  }

  const buySolar = () => {
    const solarProductToken = 3

    windowsStore.open(WINDOW_ID_CONFIRMATION, <ProductConfirmation product={SOLAR} />)
    store.payments.setToken(solarProductToken)
  }

  const editSolar = (date: string, time: string, place: string, lon: number, lat: number) => {
    setEdit(false)
    props.token && store.solar.updateSolar(props.token, date, time, place, lon, lat)
      // @ts-ignore
      .then(setData)
  }

  const print = (format: FormatsType) => {
    setFormat(format)
    store.solar.setIsPrinting(true)

    setTimeout(() => {
      window.print()
      store.solar.setIsPrinting(false)
    }, 100)
  }

  const goToEditForm = () => {
    setEdit(true)
    windowsStore.setContextMenu(null)
  }

  const closeEditForm = () => {
    setEdit(false)
    windowsStore.setContextMenu(solarContextMenu)
  }

  const onClose = () => {
    windowsStore.close(WINDOW_ID_SOLAR)
    store.synastry.setActiveSection(PRODUCTS_SECTION.CATALOG)
    history.push({ pathname: '', search: sp.toString() })
  }

  const contextMenuState = [
    [
      {
        icon: null,
        title: 'Редактировать данные',
        action: goToEditForm,
      },
    ],
    [
      {
        icon: null,
        title: 'Скопировать ссылку',
        action: saveLink,
      },
    ],
    [
      {
        icon: null,
        title: 'Скачать в PDF 24 часа',
        action: () => print(FORMAT_HOURS),
        only: ['SOLAR']
      },
      {
        icon: null,
        title: 'Скачать в PDF 12 дней',
        action: () => print(FORMAT_DAYS),
        only: ['SOLAR']
      },
    ],
    [
      {
        icon: null,
        title: 'Вернуться к покупкам',
        action: onClose,
      },
    ], [
      {
        icon: null,
        title: 'Сообщить о проблеме',
        className: 'drop',
        action: () => {
          const otherWindow = window.open();
          otherWindow!.opener = null;
          // @ts-ignore
          otherWindow!.location = s3urls.support;
        },
      },
    ],
  ];

  const contextMenuStateButton = [
    [
      {
        icon: null,
        title: 'Скопировать ссылку',
        action: saveLink,
      },
    ],
    [
      {
        icon: null,
        title: 'Скачать в PDF 24 часа',
        action: () => print(FORMAT_HOURS),
        only: ['SOLAR']
      },
      {
        icon: null,
        title: 'Скачать в PDF 12 дней',
        action: () => print(FORMAT_DAYS),
        only: ['SOLAR']
      },
    ],
  ];

  const solarContextMenu = <ContextMenu groupsItems={contextMenuState} />

  if (!data) return <></>

  const getSolarBirthDate = () => {
    const dateTime = dayjs.utc(data.calculation.solarBirthDate).format('DD MMM,  HH:mm')
    const place = data.clientOpts.place2
    return `${dateTime} ${place}`
  }

  

  return (
    <WindowContainer className={'window-container'} ref={ref}>
      <WindowHeader title='Солярный день' onBack={onClose} />
      <Container>
        {edit && <EditForm data={data.clientOpts}
          editSolar={(date: string, time: string, place: string, lon: number, lat: number) => editSolar(date, time, place, lon, lat)}
          closeEditForm={closeEditForm} />}

        {
          !edit && 
          <>
            <Header>
              <Subtitle>Дата и место солярного дня рождения</Subtitle>
              <div>{getSolarBirthDate()}</div>
            </Header>

            <Subtitle className={'no-print'}>Формат проведения соляра</Subtitle>
            <ButtonSwitcher value={format} options={solarFormatsOptions} onChange={switchFormat} />

            <Content>
              {format === FORMAT_DAYS && data.calculation.days.map((item, index) => {
                return <Sphere key={`${format}_sphere_${index}`} data={item.card} />
              })}

              {format === FORMAT_HOURS && data.calculation.hours.map((item, index) => {
                return <Sphere key={`${format}_sphere_${index}`} data={item} />
              })}
            </Content>

            {showBanner && <FreeFourteenBanner product='solar' />}

            { !props.sharedToken && 
                <Footer 
                  toPayments={onClose}
                  setContextMenuPosition={(value: { top: number, left: number }) => setContextMenuPosition(value)} 
                />
            }

            { contextMenuPosition && 
              <DropDown 
                contextButtonPosition={contextMenuPosition}
                groupsItems={contextMenuStateButton}
                onClose={() => setContextMenuPosition(null)} 
              />
            }
          </>
        }
      </Container>
    </WindowContainer>
  );
})

const Container = styled(MainPadding)`
`

const Header = styled.div`
  padding: 1rem;
  background: #FFFFFF;
  box-shadow: 0px 3.2px 14px rgba(0, 0, 0, 0.06), 0px 0.6px 1.8px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin-bottom: 1.5rem;
`

export const Subtitle = styled.div`
  color: var(--text-third);
  font-size: 0.875rem;
  margin-bottom: 0.8rem;
`

const Content = styled.div`
`
