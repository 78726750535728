import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import OfferBackground from '../../assets/images/offer_purpure_bg.jpg';
import { ChronosProLogo, DawnLogo, PortalLogo } from '../../assets/icons/services';
import { ChevronRightIcon } from '../../assets/icons/system/system-icons';
import { Avatar } from '../../components/Avatar';
import { Banner } from '../../components/banners/Banner/Banner';
import { ContentsList, Item } from '../../components/ContentsList';
import { MainPadding, WindowContainer } from '../../components/layout/elements';
import { Toaster } from '../../components/Toaster';
import { ServicesLinks } from '../../helpers/links';
import { ROUTES } from '../../helpers/routes';
import { gtmSend, searchParams } from '../../helpers/url';
import { useMoveShutter } from '../../hooks/popup-hooks';
import { useBackWindow } from '../../hooks/router-hooks';
import { history } from '../../MainRouter';
import store from '../../store/Store';
import { SubscriptionStatus } from '../../store/Subscribtion';
import { windowsStore } from '../../store/Windows';
import { openEditWindow } from '../edit/Edit';
import { openNotifications } from '../notifications/Notifications';
import { openPaymentPaywallWindow } from '../payment/components/payment-paywall';
import { TariffPeriod } from '../payment/helpers/tariffs';
import { PromocodeModal } from '../promocode/PromocodeModal';
import { openSubscriptionManagement } from '../subscription-managment/SubscriptionManagement';
import { openSupport } from '../support/Support';
import { WindowHeader } from '../windows/components/WindowHeader';
import { openConsultationSignUp } from '../consultation-sign-up/ConsultationSignUp';
import { openProducts } from '../products/Products';


export const WINDOW_ID_PROFILE: string = 'WINDOW_ID_PROFILE';

export const openProfile = () => {
  windowsStore.open(
    WINDOW_ID_PROFILE,
    <Profile />,
    undefined,
    false,
    true,
  )
}

export const Profile = observer(() => {
  const [showPromocodeModal, setShowPromocodeModal] = useState(false)
  const [showToaster, setShowToaster] = useState('')

  const containerRef = useRef<HTMLDivElement | null>(null)
  const signOut = async () => {
    await store.signOut()
  }

  const openPromocode = () => {
    gtmSend({'event': 'indi_profile_promo', 'user_id': userId})
    setShowPromocodeModal(true)
  }

  const openMyData = () => {
    openEditWindow({
      showToaster: () => showToasterHandler('Заявка отправлена')
    })
    gtmSend({'event': 'indi_profile_my_recti_data', 'user_id': userId})
  }

  const openSupportHandler = () => {
    openSupport(() => showToasterHandler('Обращение отправлено'))
    gtmSend({'event': 'indi_profile_support', 'user_id': userId})
  }

  const openNotificationHandler = () => {
    openNotifications()
    gtmSend({'event': 'indi_push_notifications', 'user_id': userId})
  }

  const getStatus = () => {
    if (store.subscription.subscriptionStatus === SubscriptionStatus.ACTIVE) {
      return `Подписка ${store.subscription.subscriptionStatus.toLowerCase()}`
    } else {
      return store.subscription.subscriptionStatus
    }
  }

  const openSubscriptionManagementHandler = () => {
    openSubscriptionManagement()
    gtmSend({'event': 'indi_profile_subs_status', 'user_id': userId})
  }

  const chronosPlus: Item[] = [
    {
      title: getStatus(),
      text: 'Управлять',
      goTo: openSubscriptionManagementHandler,
    },
    {
      title: 'Ввести промокод для Chronos Plus',
      onClick: openPromocode,
    }
  ]

  const commonSettings: Item[] = [
    {
      title: 'Мои данные',
      goTo: openMyData,
    },
    {
      title: 'Уведомления',
      goTo: openNotificationHandler,
    },
    {
      title: 'Поддержка',
      goTo: openSupportHandler,
    },
    {
      title: 'Выйти из аккаунта',
      colorTitle: 'var(--color-secondary-red)',
      onClick: signOut,
    }
  ]

  const openChronosPro = () => {
    window.location.href = ServicesLinks.CHRONOS_PRO
    gtmSend({'event': 'indi_profile_services_pro', 'user_id': userId})
  }

  const openDawn = () => {
    window.location.href = ServicesLinks.DAWN
    gtmSend({'event': 'indi_profile_services_dawn', 'user_id': userId})
  }

  const openPortal = () => {
    window.location.href = ServicesLinks.PORTAL
    gtmSend({'event': 'indi_profile_services_site', 'user_id': userId})
  }

  const anotherServices: Item[] = [
    {
      icon: <ChronosProLogo/>,
      title: 'Chronos Pro',
      subtitle: 'Инструменты для астрологов',
      goTo: openChronosPro,
    },
    {
      icon: <DawnLogo/>,
      title: 'Рассвет',
      subtitle: 'Путь развития личности',
      goTo: openDawn,
    },
    {
      icon: <PortalLogo/>,
      title: 'Портал',
      subtitle: 'Новости и статьи об астрологии',
      goTo: openPortal,
    },
  ]

  useBackWindow(ROUTES.PROFILE, WINDOW_ID_PROFILE);

  const shutterRef = store.shutter.shutterRef
  const user = store.profile
  const userId = store.sessionData?.id
  const tariffs = store.events.tariffs
  const subscriptionInfo = store.sessionData?.indi
  const status = store.events.status
  const showYearBanner = status !== 'active' || tariffs.find(({id}) => id === subscriptionInfo?.tariffId)?.period !== 12
  const rectificationSent = store.rectification.rectificationSent

  const date = dayjs.utc(user.birth.dateTime).format('DD MMM YYYY, HH:mm')

  const sp = searchParams()

  useMoveShutter(containerRef, shutterRef)

  const buyYearSubscription = () => {
    const onClose = async () => {
      await store.refresh()
      windowsStore.closeAll()
    }

    openPaymentPaywallWindow(TariffPeriod.YEAR, onClose)
  }

  const onClickRectificationBannerSubmit = async () => {
    await store.userProfile.getProfile();
    const phoneNumber = store.userProfile.profile?.phoneNumber;
    if (!phoneNumber) {
      openConsultationSignUp(onClickRectificationBanner, 'Запись на ректификацию')
    } else {
      onClickRectificationBanner();
    }
  };

  const onClickRectificationBanner = () => {
    store.rectification.orderRectification()
      .then(() => {
        gtmSend({'event': 'ind_prognosis_settings_rectification', 'user_id': userId})
      })
  }

  const getShowRectificationBanner = () => {
    if (store.rectification.rectificationDelay) {
      return dayjs().diff(dayjs(store.rectification.rectificationDelay), 'month', true) > 1 ? true : false
    } else {
      return true
    }
  }

  const showToasterHandler = (title: string) => {
    setShowToaster(title)
    setTimeout(() => setShowToaster(''), 3000)
  }

  const setSubscriptionStatus = useCallback(() => {
    if (subscriptionInfo?.isTrial || subscriptionInfo?.isCardlessTrial) {
      store.subscription.setSubscriptionStatus(SubscriptionStatus.TRIAL)
      return
    }

    if (status === 'active') {
      store.subscription.setSubscriptionStatus(SubscriptionStatus.ACTIVE)
      return
    }

    if (
      dayjs().diff(dayjs(subscriptionInfo?.expiryDate), 'day') < 4 &&
      subscriptionInfo?.autoPayment
    ) {
      store.subscription.setSubscriptionStatus(SubscriptionStatus.NOT_PASSED)
      return
    }

    store.subscription.setSubscriptionStatus(SubscriptionStatus.INACTIVE)
  }, [subscriptionInfo, status])

  useEffect(() => {
    return () => {
      store.rectification.loadRectificationDelay()
    }
  }, [])

  useEffect(() => {
    history.push({pathname: '/profile', search: sp.toString()})
  }, [sp])


  useEffect(() => {
    setSubscriptionStatus()
  }, [setSubscriptionStatus, status])

  const checkNotifications = useCallback(async () => {
    if (!userId) return

    const showedModal = await store.webPush.getModal(userId)

    if (
      store.webPush.isPWA() &&
      !store.webPush.detectOldUser() &&
      !showedModal
    ) {
      store.modal.showHandler.notificationsModal = true
    }
  }, [userId])

  useEffect(() => {
    checkNotifications()
  }, [checkNotifications])

  const goToProducts = () => {
    openProducts();
  }

  return (
    <WindowContainer ref={containerRef}>
      <WindowHeader title='Профиль'/>
      <Content>
        <Header>
          <Avatar
            userId={userId}
            name={user.firstName}
          />

          {user.firstName && <div className={'name'}>{user.firstName} {user.lastName}</div>}
          {user.birth.dateTime && <div className={'date_place'}>{date}, {user.birth.place?.name}</div>}
        </Header>

        <StyledContentsList
          title={'Ваш Chronos Plus'}
          items={chronosPlus}
        />

        <StyledContentsListInfo>
          Если промокод не работает, попробуйте его ввести в разделе <span onClick={goToProducts}>Продукты</span>
        </StyledContentsListInfo>

        {
          showYearBanner && (
            <StyledBanner
              title='Получите 2 месяца в подарок'
              text='При оплате годовой подписки Chronos Plus'
              buttonContent={<ButtonContent>Узнать больше <ChevronRightIcon/></ButtonContent>}
              onClick={buyYearSubscription}
              backgroundImg={OfferBackground}
            />
          )
        }

        <StyledContentsList
          title={'Общие настройки'}
          items={commonSettings}
        />

        {
          getShowRectificationBanner() && (
            <StyledBanner
              title='Узнайте точное время рождения'
              text='Свяжемся с вами, чтобы рассказать об услуге ректификации (уточнение времени рождения)'
              buttonContent={<ButtonContent>{rectificationSent ? 'Заявка отправлена' : 'Отправить заявку'} <ChevronRightIcon/></ButtonContent>}
              onClick={onClickRectificationBannerSubmit}
            />
          )
        }

        <StyledContentsList
          title={'Другие сервисы Chronos'}
          items={anotherServices}
        />
      </Content>

      {
        showPromocodeModal && (
          <PromocodeModal
            onClose={() => setShowPromocodeModal(false)}
            showToaster={() => showToasterHandler('Промокод успешно применен')}/>
        )
      }

      {
        showToaster && (
          createPortal(
            <ToasterWrapper>
              <Toaster
                title={showToaster}
                background={'var(--color-green)'}
              />
            </ToasterWrapper>,
            document.body
          )
        )
      }
    </WindowContainer>
  )
})

const Content = styled(MainPadding)`
  flex: 1;

  padding-bottom: 8rem;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  text-align: center;

  .name {
    font-size: 1.25rem;
    font-weight: 500;
    margin-top: 0.5rem;
  }

  .date_place {
    font-size: 0.825rem;
    font-weight: 400;
    color: var(--text-third);
    margin-top: 0.5rem;
  }
`

const StyledBanner = styled(Banner)`
  margin-top: 1.5rem;
  min-height: 126px;

  .title {
    font-size: 1.125rem;
    color: var(--text-primary);
    width: 100%;
  }

  .text {
    color: var(--text-primary);
  }

  .banner_button {
    margin-top: 1.25rem;
    width: fit-content;
  }
`

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.875rem;
  padding: 0 0.5rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: var(--color-white);
  }
`
const ToasterWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const StyledContentsList = styled(ContentsList)`
  margin-top: 1.5rem;
`

const StyledContentsListInfo = styled.div`
  font-size: 0.825rem;
  color: var(--text-third);
  margin-top: 0.4rem;

  > span {
    color: var(--color-blue);
    text-decoration: underline;
  }
`
