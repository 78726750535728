import { getUTMCommentForCP } from "../libs";
import dayjs from "dayjs";
import { makeAutoObservable } from "mobx";
import api from "src/helpers/api";
import { gtmSend } from "src/helpers/url";

export const trialDurations = [7, 14, 30]

export class Trial {
  constructor() {
    makeAutoObservable(this)
  }

  activeTrial: boolean = false

  setActiveTrial(trialExpiryDate: string) {
    this.activeTrial = dayjs().isBefore(trialExpiryDate)
  }

  async getTrialWithoutCard(duration: number) {
    try {
      const dataPost = {
        duration,
        utm: getUTMCommentForCP(),
      }

      await api.indiGetTrialWithoutCard(dataPost)
      gtmSend({ event: 'indi_trial_start' })
    } catch (e) {
      console.error(`Getting trial without card error: ${e}`)
    }
  }
}
