import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { getPWALS, setPWALS } from "src/store/localStorageHelpers";


export const useAppInstall = () => {
  const deferredPrompt = useRef<any>();
  const [isVisible, setIsVisible] = useState(false);
  const pwaLS = getPWALS()

  const checkPWALS = () => {
    const delay = dayjs().diff(dayjs(pwaLS.dateClose), 'day', true)

    if (pwaLS.closed === '1') {
      return delay > 1 ? false : true
    }

    if (pwaLS.closed === '2' || pwaLS.closed === '3') {
      return delay > 7 ? false : true
    }

    if (pwaLS.closed === '4') {
      return true
    }

    return false
  }

  useEffect(() => {
    const beforeInstall = (e?: Event) => {
      e && e.preventDefault();
      if(window.matchMedia('(display-mode: standalone)').matches) return; // 1 проверка на браузер
      if((navigator as any).standalone) return; // 1 проверка на браузер
      if(document.referrer.includes('android-app://')) return; // 1 проверка на браузер
      if(!isMobile) return; // 2 проверка на мобилку
      if(checkPWALS()) return

      const pages = ['data_filling', 'trial']
      if(
        pages.find(page => window.location.href.toLowerCase().includes(page))
      ) return

      deferredPrompt.current = e;
      setIsVisible(true);
    }

    // const afterInstall = () => {
    //   if(iOS()) localStorage.setItem(PWA_INSTALLED_LS, '1');
    //   setIsVisible(false);
    // }

    //if(iOS()){
    beforeInstall();
    // } else {
    //   window.addEventListener('beforeinstallprompt', beforeInstall);
    //   window.addEventListener('appinstalled', afterInstall);
    //   return () => {
    //     window.removeEventListener('beforeinstallprompt', beforeInstall);
    //     window.removeEventListener('appinstalled', afterInstall);
    //   }
    // }
  }, [])

  const installApp = async () => {
    pwaLS.closed = String(pwaLS.closed ? +pwaLS.closed + 1 : 1)
    pwaLS.dateClose = dayjs().toISOString()
    setPWALS(pwaLS)
    setIsVisible(false)
  }

  return {
    isVisible,
    installApp
  }
}

// export function iOS() {
//   return [
//     'iPad Simulator',
//     'iPhone Simulator',
//     'iPod Simulator',
//     'iPad',
//     'iPhone',
//     'iPod'
//   ].includes(navigator.platform)
//   // iPad on iOS 13 detection
//   || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
// }
