import React from 'react';
import styled, { css } from 'styled-components';
import { CheckedGreenIcon } from '../../../assets/icons/system/system-icons';
import { Indi } from 'src/libs';

export const TariffSwitcherNew = ({
  tariffs,
  current,
  setTariff,
  discount,
	symbol,
  className
}:{
  tariffs: Indi.indiGetTariffs.Tariff[],
  current: Indi.indiGetTariffs.Tariff,
  setTariff: (tariff: Indi.indiGetTariffs.Tariff) => void;
	discount: number,
	symbol: string,
  className?: string
}) => {
  return (
    <TariffSwitcherContainer className={className}>
      {tariffs.map(tariff => {
        const checked = tariff.id === current.id;
        const _oldPrice = (tariff.oldPrice ?? tariff.price);
        const _discount = discount ? discount * 100 : Math.ceil( (( _oldPrice - tariff.price) / _oldPrice) * 100 );
			  const finalPrice = Math.round(tariff.price * (1 - discount));
        const periodText = tariff.title.replace(/^1/, '');
        const perPeriodtext = _discount ? `${Math.round(tariff.price / tariff.period)}${symbol} / мес` : `${tariff.price}${symbol} / мес`

        return (
          <Item key={tariff.id} onClick={() => setTariff(tariff)} checked={checked} className='item'>

            {Boolean(_discount) && <div className="item-badge">-{_discount}%</div>}

            <div className='item-header'>
              <span className='item-period'>{periodText}</span>
            </div>

            <div className='item-content'>
              <div className="item-price"> {finalPrice} {symbol}</div>
              {
                (tariff.oldPrice || discount > 0) &&
                <div className='item-old-price'>
                    { discount > 0 ? tariff.price : tariff.oldPrice} {symbol}
                </div>
              }
            </div>
            <div className="item-footer">
              <div className="tariff-title">{perPeriodtext}</div>
              {checked && <div className="checked-icon"><CheckedGreenIcon /></div>}
            </div>
        </Item>
      );
    })}

  </TariffSwitcherContainer>
  );
}

const TariffSwitcherContainer = styled.div`
  display: flex;
  position: relative;
  padding: 0.125rem;
  gap: 0.75rem;
  position: relative;
`

const Item = styled.div<{checked: boolean}>`
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1;
  aspect-ratio: 2/2.3;
  padding: 1rem;
  border-radius: 1rem !important;
  color: var(--text-primary);

  cursor: pointer;
  outline: 0.063rem solid var(--element-border-10);
  transition: outline-color .3s ease;

  ${p => p.checked && css`
    transition: outline-color .3s ease;
    outline: 0.125rem solid var(--color-green);
  `}

  & .item-badge {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(10%, -50%);

    padding: 0.219rem 0.5rem;
    font-size: 1.094rem;
    color: var(--text-primary);

    border-radius: 4.375rem;
    background-color: var(--color-ocher);
  }

  & .item-header {
    display: flex;
    font-size: 0.813rem;
    font-weight: 400;
    color: var(--text-secondary);

    & .item-period::first-letter {
      text-transform: uppercase;
    }
  }

  & .item-content {
    position: relative;

    & .item-price {
      margin-top: 0.375rem;

      font-size: 2rem;
      font-weight: 500;
      line-height: 1.1;
      color: var(--text-primary)
    }

    & .item-old-price {
      margin-top: 0.125rem;

      font-size: 1rem;
      color: var(--text-third);
      text-decoration: line-through;
    }
  }

  & .item-footer {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: auto;

    & .tariff-title {
      font-size: 1rem;
      color: var(--text-secondary);
    }

    svg {
      width: 1.875rem;
      height: 1.875rem;
    }

    ${p => p.checked && css`

    `}
  }


`
