import { s3urls } from "src/helpers/api"
import styled from "styled-components"

export const DealAcceptance = () => {
  return (
    <Container>
      Я принимаю&nbsp;
      <a href={s3urls.termsOfUseRU} target="_blank" rel="noreferrer">пользовательское соглашение</a>
      &nbsp;и&nbsp;
      <a href={s3urls.privacyPolicyRU} target="_blank" rel="noreferrer">политику конфиденциальности</a>
    </Container>
  )
}

const Container = styled.div`
  font-size: 0.7rem;
  color: var(--text-secondary);
  line-height: 140%;
  text-align: left;

  > a {
    text-decoration: underline;
    color: var(--color-gradus-blue);
  }
`
